define("formaviva-web/_pods/playlists/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBBKuMoH",
    "block": "{\"symbols\":[\"playlist\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row pb-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fv-breadcrumbs\"],[9],[0,\"\\n      Playlists\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-variant-1\"],[9],[0,\"\\n      \"],[1,[29,\"fa-icon\",[\"plus\"],null],false],[0,\" Create New Playlist\\n    \"],[3,\"action\",[[24,0,[]],\"transitionToNewPlaylist\"]],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"model\"]],\"playlists\",[25,[\"page\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"fv-box-list\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fv-box\",null,[[\"playlist\",\"class\",\"data-test-playlists\",\"data-test-playlist\"],[[24,1,[]],\"fv-box-list__item\",true,true]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"fv-box-list__placeholder\"],[11,\"data-test-playlists\",\"\"],[9],[0,\"You currently have no playlists.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"model\"]],\"playlists\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/playlists/template.hbs"
    }
  });

  _exports.default = _default;
});