define("formaviva-web/mirage/config", ["exports", "ember-cli-mirage", "miragejs", "formaviva-web/config/environment"], function (_exports, _emberCliMirage, _miragejs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.get("https://ipinfo.io", function () {
      return {
        country: "RU"
      };
    });
    this.get("https://api.mixpanel.com/decide", function () {
      return {
        notifications: [],
        config: {
          enable_collect_everything: false
        }
      };
    });
    this.get("https://api.mixpanel.com/engage", function () {
      return 1;
    });
    this.post("http://formaviva-prod.s3-eu-west-1.amazonaws.com", function () {
      return new _miragejs.Response(200, {}, {});
    });
    this.get("https://api.mixpanel.com/track", function () {
      return 0;
    });
    this.post("https://api.exitmist.com/v", function () {
      return {
        visit: {
          visitor_code: "BAhpA65XoQ==--01d7a2900b6c5998f35f655cb60ed4e9afc9395e",
          visit_code: "BAhpBIaqQQE=--32c227c3aa5c41027cb64a84795e1c9cc8249c87",
          campaign_website: "Formaviva.com",
          triggers: [{
            id: 234,
            action: {
              name: "popup:open",
              parameters: ["z5dyyzlpmYaHoi84yv7G4gKPjkg"],
              source: {
                trigger_id: 234
              }
            },
            on: "load",
            rules: [[{
              field: "events.popup:close",
              condition: "ni",
              value: {
                trigger_id: 234,
                popup_token: "z5dyyzlpmYaHoi84yv7G4gKPjkg"
              },
              auto: "prevent_after_close"
            }], [{
              field: "events.lead:submit",
              condition: "ni",
              value: {
                trigger_id: 234,
                popup_token: "z5dyyzlpmYaHoi84yv7G4gKPjkg"
              },
              auto: "prevent_after_submit"
            }], [{
              field: "events.goto:url",
              condition: "ni",
              value: {
                trigger_id: 234,
                popup_token: "z5dyyzlpmYaHoi84yv7G4gKPjkg"
              },
              auto: "prevent_after_goto_url"
            }]],
            name: "Main Popup"
          }],
          popups: [{
            id: 205,
            token: "z5dyyzlpmYaHoi84yv7G4gKPjkg",
            html: "\n\t\t<article class=\"em-container\">\n\t\t\t<div class=\"em-body\" style=\"width: 360px; height: 155px; \">\n\t\t\t\t\t<section class=\"em-view em-view-1\" style=\"background-image: url('https://static.exitmist.com/1/z5dyyzlpmYaHoi84yv7G4gKPjkg-slide-0-1540894010314.jpg');\">\n\t\t\t\t\n\t\t\t\t<div class=\"em-input-text em-style-1\" style=\"width: 53.89%; height: 22.58%; position: absolute; top: 65.80645161290323%; left: 8.055555555555555%;\">\n\t\t\t<input id=\"em_input_2\" type=\"email\" name=\"email\" placeholder=\"Enter your email\" style=\"width: 100%; height: 100%;\">\n\n\t\t\t<div class=\"em-input-alerts\" style=\"display: none\">\n\t\t\t\t<span class=\"em-input-error\"></span>\n\t\t\t</div>\n\t\t</div>\n\t\t\t\t<div class=\"em-action\" data-em-action=\"lead:submit\" data-em-parameters=\"{&quot;closePopup&quot;:true}\" style=\"position: absolute; top: 63.87096774193548%; left: 64.44444444444444%; width: 28.76%; height: 25.81%;\"></div>\n\t\t</section>\n\t\t\t</div>\n\t\t\t<button type=\"button\" class=\"em-exit\" data-em-action=\"popup:close\"></button>\n\t\t</article>",
            css: "",
            settings: {
              show_overlay: false,
              show_close_button: true,
              style: "1",
              image_format: {
                format: "jpeg",
                quality: 0.9
              },
              is_sticky: true,
              sticky_position: "bottom-right",
              store_retina: true
            },
            images: ["https://static.exitmist.com/1/z5dyyzlpmYaHoi84yv7G4gKPjkg-slide-0-1540894010314.jpg"]
          }]
        }
      };
    });
    this.post("https://api.exitmist.com/e", function () {});
    this.post("/oauth/token", function () {
      return {
        access_token: _environment.default.oauth.access_token,
        token_type: "bearer",
        expires_in: 7200,
        refresh_token: _environment.default.oauth.refresh_token
      };
    });
    this.namespace = "/api/v1";
    this.get("users/me", function (_ref) {
      var users = _ref.users;
      var user = users.findBy({
        me: true
      });
      return this.serialize(user);
    });
    this.patch("users/:id");
    this.patch("/profiles/:id");
    this.get("/profiles/:id", function (_ref2, request) {
      var profiles = _ref2.profiles;
      var findBySlug = request.queryParams.find_by_slug;
      var profile = findBySlug ? profiles.findBy({
        slug: request.params.id
      }) : profiles.find(request.params.id);

      if (profile) {
        return this.serialize(profile);
      } else {
        return new _emberCliMirage.default.Response(404, {
          "Content-Type": "application/json"
        }, {
          errors: []
        });
      }
    });
    this.get("profiles/engagements", function (db, request) {
      return this.serialize(db.profiles.all());
    });
    this.get("profiles/public", function (db, request) {
      var sort = request.queryParams.sort;
      var profilesObject = db.profiles.where({
        approved: true
      });
      var followedFilter = request.queryParams["filter[followed]"];

      if (followedFilter === "true") {
        profilesObject = db.profiles.where({
          approved: true,
          followed: true
        });
      }

      var models = profilesObject.models;
      var sortedProfiles = models;

      switch (sort) {
        case "-approved_at":
          sortedProfiles = models.sortBy("approved_at").reverse();
          break;

        case "approved_at":
          sortedProfiles = models.sortBy("approved_at");
          break;

        case "-name":
          sortedProfiles = models.sortBy("name").reverse();
          break;

        case "name":
          sortedProfiles = models.sortBy("name");
          break;
      }

      profilesObject.models = sortedProfiles;
      return this.serialize(profilesObject);
    });
    this.get("/profiles/search", function (db, request) {
      var term = request.queryParams["filter[term]"] || "";
      var artistsObjects = db.profiles.where({
        profileType: "artist"
      });
      var filteredObjects = artistsObjects.models.filter(function (profile) {
        return profile.name.toLowerCase().includes(term.toLowerCase());
      });
      artistsObjects.models = filteredObjects;
      return this.serialize(artistsObjects);
    });
    this.post("/profiles/:id/toggle_follow", function (db, request) {
      var id = request.params.id;
      var profile = db.profiles.find(id);

      if (profile.followed) {
        profile.followed = false;
        profile.followers_count -= 1;
      } else {
        profile.followed = true;
        profile.followers_count += 1;
      }

      profile.save();
    });
    this.post("/profiles/:id/toggle_follow", function (db, request) {
      var id = request.params.id;
      var profile = db.profiles.find(id);

      if (profile.followed) {
        profile.followed = false;
        profile.followers_count -= 1;
      } else {
        profile.followed = true;
        profile.followers_count += 1;
      }

      profile.save();
    });
    this.post("/profiles", function (db, request) {
      var profileAttrs = JSON.parse(request.requestBody).data.attributes;
      return db.profiles.create(profileAttrs);
    });
    this.post("/shipping_origins");
    this.post("/uploads", function () {
      return {};
    });
    this.post("/s3/upload_success", function () {
      return {};
    });
    this.post("/s3/signature", function () {
      return {
        policy: "eyJleHBpcmF0aW9uIjoiMjAyMC0wNC0wNlQwOTozNTowNy4wOTBaIiwiY29uZGl0aW9ucyI6W3siYWNsIjoicHJpdmF0ZSJ9LHsiYnVja2V0IjoiZm9ybWF2aXZhLXN0YWdpbmcifSx7IkNvbnRlbnQtVHlwZSI6ImF1ZGlvL21wMyJ9LHsic3VjY2Vzc19hY3Rpb25fc3RhdHVzIjoiMjAwIn0seyJrZXkiOiI4Ni90cmFja3MvMTQ3NC9vLzQ1OTg3ZTgyLTVmMTYtNDhlNy1hZTk0LWM1Yjc2YTg2MjViMS5tcDMifSx7IngtYW16LW1ldGEtcXFmaWxlbmFtZSI6ImRhd2QxMS5tcDMifV19",
        signature: "LMFZFkCBK8IxoWDbogh0dpLmbIA="
      };
    });
    this.get("/shipping_origins");
    this.patch("/shipping_origins/:id");
    this.get("/purchases");
    this.get("/purchases/:id");
    this.get("/play_sessions");
    this.post("/users", function (db, request) {
      var attributes = JSON.parse(request.requestBody).data.attributes;
      var errors = [];
      var emailRegex = /\S+@\S+\.\S+/;

      if (attributes.email === null) {
        errors.pushObject({
          detail: "Email address can't be blank",
          source: {
            pointer: "/data/attributes/email"
          }
        });
      } else if (!emailRegex.test(attributes.email)) {
        errors.pushObject({
          detail: "Invalid email address",
          source: {
            pointer: "/data/attributes/email"
          }
        });
      }

      if (attributes.name === null) {
        errors.pushObject({
          detail: "Username can't be blank",
          source: {
            pointer: "/data/attributes/name"
          }
        });
      }

      if (attributes.password === null) {
        errors.pushObject({
          detail: "Password can't be blank",
          source: {
            pointer: "/data/attributes/password"
          }
        });
      } else if (attributes.password.length < 6) {
        errors.pushObject({
          detail: "Password is too short(minimum 6 characters)",
          source: {
            pointer: "/data/attributes/password"
          }
        });
      }

      var user = db.users.findBy({
        email: attributes.email
      });

      if (user) {
        errors.pushObject({
          detail: "Email address has already been taken",
          source: {
            pointer: "/data/attributes/email"
          }
        });
      }

      if (errors.length > 0) {
        return new _emberCliMirage.default.Response(422, {}, {
          errors: errors
        });
      }

      var profile = db.profiles.create(Object.assign({}, attributes, {
        slug: "123"
      }));
      attributes.me = true;
      attributes.profile = profile;
      user = db.users.create(attributes);
      return this.serialize(user);
    });
    this.get("tracks/:id", function (_ref3, request) {
      var tracks = _ref3.tracks;
      var findBySlug = request.queryParams.find_by_slug;
      var track = findBySlug ? tracks.findBy({
        slug: request.params.id
      }) : tracks.find(request.params.id);
      return this.serialize(track);
    });
    this.get("tags", function (db, request) {
      var tag = Ember.isEmpty(request.queryParams["slug"]) ? db.tags.all() : db.tags.where({
        slug: request.queryParams["slug"]
      });
      return this.serialize(tag);
    });
    this.get("tracks");
    this.post("tracks");
    this.patch("tracks/:id");
    this.get("tracks/public", function (db, request) {
      return this.serialize(db.tracks.all());
    });
    this.get("tracks/engagements", function (db, request) {
      return this.serialize(db.tracks.all());
    });
    this.get("/static_pages", function (_ref4, request) {
      var staticPages = _ref4.staticPages;
      var slug = request.queryParams["filter[slug]"];
      staticPages = staticPages.all().filter(function (a) {
        return a.slug === slug;
      });
      return this.serialize(staticPages);
    });
    this.get("merches");
    this.get("merches/public", function (db, request) {
      return this.serialize(db.merches.all());
    });
    this.get("releases/public", function (db, request) {
      var sort = request.queryParams.sort;
      var followedFilter = request.queryParams["filter[followed]"];
      var filterByType = request.queryParams["filter[item_type]"];
      var filterByProfileSlug = request.queryParams["filter[profile_slug]"];
      var releasesObject = db.releases.all();
      var filteredReleases;

      if (followedFilter === "true") {
        var profilesObject = db.profiles.all();
        var profileModels = profilesObject.models;
        var profiles = profileModels.filterBy("followed", true);
        var followedProfilesReleases = [];
        profiles.forEach(function (profile) {
          var profileReleases = releasesObject.models.filterBy("profile.id", profile.id);
          followedProfilesReleases.pushObject(profileReleases);
        });
        filteredReleases = followedProfilesReleases.reduce(function (total, amount) {
          return total.concat(amount);
        }, []);
      } else if (filterByProfileSlug) {
        filteredReleases = releasesObject.models.filterBy("profile.slug", filterByProfileSlug);
      } else {
        filteredReleases = releasesObject.models;
      }

      switch (filterByType) {
        case "digital":
          filteredReleases = filteredReleases.filter(function (model) {
            return model.merches.length === 0;
          });
          break;

        case "physical":
          filteredReleases = filteredReleases.filter(function (model) {
            return model.merches.length > 0;
          });
          break;
      }

      var sortedReleases = filteredReleases;

      switch (sort) {
        case "-release_date":
          sortedReleases = filteredReleases.sortBy("release_date").reverse();
          break;

        case "released_date":
          sortedReleases = filteredReleases.sortBy("release_date");
          break;

        case "-published_at":
          sortedReleases = filteredReleases.sortBy("published_at").reverse();
          break;

        case "published_at":
          sortedReleases = filteredReleases.sortBy("published_at");
          break;

        case "-name":
          sortedReleases = filteredReleases.sortBy("name").reverse();
          break;

        case "name":
          sortedReleases = filteredReleases.sortBy("name");
          break;
      }

      releasesObject.models = sortedReleases;
      return this.serialize(releasesObject);
    });
    this.get("/releases");
    this.post("/releases");
    this.patch("/releases/:id");
    this.get("/releases/:id", function (db, request) {
      var findBySlug = request.queryParams.find_by_slug;
      var release = findBySlug ? db.releases.findBy({
        slug: request.params.id
      }) : db.releases.find(request.params.id);
      return this.serialize(release);
    });
    this.get("/feed", function (db, request) {
      var profiles = db.profiles.all();
      var tracks = db.tracks.all();
      var responseArray = [];
      var feedResourceId;
      var feedResourceType;
      var profileId;

      if (tracks.models.length === 0) {
        return {
          data: []
        };
      }

      feedResourceId = tracks.models[0].id;
      feedResourceType = "track";
      profileId = profiles.models[2].id;
      responseArray.pushObject({
        feed_resource_id: feedResourceId,
        feed_resource_type: feedResourceType,
        id: "liked_track_" + feedResourceId,
        activity: "liked_track",
        activity_time: new Date().toISOString(),
        profile: profileId
      });
      feedResourceId = tracks.models[1].id;
      feedResourceType = "track";
      profileId = profiles.models[2].id;
      responseArray.pushObject({
        feed_resource_id: feedResourceId,
        feed_resource_type: feedResourceType,
        id: "uploaded_track_" + feedResourceId,
        activity: "uploaded_track",
        activity_time: new Date().toISOString(),
        profile: profileId
      });
      feedResourceId = profiles.models[1].id;
      feedResourceType = "profile";
      profileId = profiles.models[2].id;
      responseArray.pushObject({
        feed_resource_id: feedResourceId,
        feed_resource_type: feedResourceType,
        id: "followed_profile_" + feedResourceId,
        activity: "followed_profile",
        activity_time: new Date().toISOString(),
        profile: profileId
      });
      return responseArray;
    });
    this.get("/profile_stats/stats", function () {
      return {};
    });
    this.get("/profile_stats/payout", function () {
      return {};
    });
    this.post("/user_behaviour/track", function () {
      return {};
    });
    this.post("/analytics_events", function () {
      return {};
    });
    this.get("/shopping_cart/payment_amounts", function () {
      return {
        total: 12,
        subtotal: 10,
        vat: 2,
        shipping: 0
      };
    });
    this.get("/billing/payment_token", function () {
      return {
        token: "123"
      };
    });
    this.get("/profile_stats/stats", function () {
      return {
        plays: 0,
        plays_change: -7,
        visits: 6,
        visits_change: -54,
        downloads: 0,
        downloads_change: 0,
        purchases: 0,
        purchases_change: 0,
        revenue: 0.0,
        revenue_change: 0.0
      };
    });
    this.get("/playlists");
  }
});